@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-sans
  }
  *::-webkit-input-placeholder {
    color: #686868 !important;
    font-weight: bold;
  }
  *:-moz-placeholder {
    color: #686868 !important;
    font-weight: bold;
  }
  *::-moz-placeholder {
    color: #686868 !important;
    font-weight: bold;
  }
  *:-ms-input-placeholder {
    color: #686868 !important;
    font-weight: bold;
  }
}

@layer components {}